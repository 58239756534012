import React from "react";
import { stringIncludes } from "../utils/utils";
import iconTruck from "../Assets/icon/truck.png";
import iconCashier from "../Assets/icon/cashier-machine.png";
import iconCook from "../Assets/icon/cooking.png";
import iconPaint from "../Assets/icon/paint.png";
import iconLogistic from "../Assets/icon/logistic.png";
import iconWaiter from "../Assets/icon/waiter.png";
import iconGuard from "../Assets/icon/guard.png";
import iconCeo from "../Assets/icon/ceo.png";
import iconManager from "../Assets/icon/manager.png";
import iconCheklist from "../Assets/icon/checklist.png";
import iconWarehouse from "../Assets/icon/warehouse.png";
import iconMaintenance from "../Assets/icon/maintenance.png";
import iconFinance from "../Assets/icon/finance.png";
import iconHr from "../Assets/icon/hr.png";

const IconPosition = ({ position }) => {
 if (
  !position ||
  !iconTruck ||
  !iconCashier ||
  !iconCook ||
  !iconPaint ||
  !iconLogistic ||
  !iconWaiter ||
  !iconGuard ||
  !iconCeo ||
  !iconManager ||
  !iconCheklist ||
  !iconWarehouse ||
  !iconMaintenance ||
  !iconFinance ||
  !iconHr
 ) {
  return <></>;
 }
 const findPosition = () => {
  if (stringIncludes(position, "driver")) {
   return iconTruck;
  }
  if (stringIncludes(position, "kasir")) {
   return iconCashier;
  }
  if (stringIncludes(position, "cook") || stringIncludes(position, "kitchen")) {
   return iconCook;
  }
  if (
   stringIncludes(position, "creative") ||
   stringIncludes(position, "designer")
  ) {
   return iconPaint;
  }
  if (stringIncludes(position, "helper")) {
   return iconLogistic;
  }
  if (stringIncludes(position, "service")) {
   return iconWaiter;
  }
  if (stringIncludes(position, "security")) {
   return iconGuard;
  }
  if (stringIncludes(position, "finance")) {
   return iconFinance;
  }
  if (stringIncludes(position, "hr")) {
   return iconHr;
  }
  if (
   stringIncludes(position, "hdo") ||
   stringIncludes(position, "spv") ||
   stringIncludes(position, "manager")
  ) {
   return iconCheklist;
  }
  if (stringIncludes(position, "gudang")) {
   return iconWarehouse;
  }
  if (stringIncludes(position, "maintenance")) {
   return iconMaintenance;
  }
  if (
   stringIncludes(position, "ceo") ||
   stringIncludes(position, "coo") ||
   stringIncludes(position, "cfo")
  ) {
   return iconCeo;
  }

  return false;
 };

 if (!findPosition()) {
  return <></>;
 }
 return (
  <div className="logo-container" style={{ position: "relative", top: "-5px" }}>
   <img
    src={findPosition()}
    alt="position"
    style={{
     width: "20px",
     height: "20px",
    }}
   />
  </div>
 );
};

export default IconPosition;
