/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Employee from "../components/employee";
import Loading from "../components/loader.jsx";
import Message from "../components/message.jsx";
import { listEmployees } from "../actions/employeeActions";
import Paginate from "../components/paginate";
import { Route } from "react-router-dom";
import SearchBox from "../components/searchBox";
import {
 Row,
 Col,
 ToggleButtonGroup,
 ToggleButton,
 Container,
 Form,
} from "react-bootstrap";
import EmployeesTableView from "./employeeTableView";
import { TooltipIcon } from "../components/tooltip";
import {
 options,
 capitalizeEachWord,
 countActiveEmployee,
 countStatusEmployee,
 stringIncludes,
} from "../utils/utils.jsx";
import { getSheetDetails } from "../actions/sheetActions";
import dayjs from "dayjs";
import verifiedIcon from "../Assets/icon/verified.png";
import { logout } from "../actions/userActions";

const EmployeeListView = ({ match, history }) => {
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;
 const [isGrid, setIsGrid] = useState(true);
 const activeEmployee = "Active employee";
 const [showActiveEmployee, setShowActiveEmployee] = useState(activeEmployee);
 const [isActiveEmployee, setIsActiveEmployee] = useState(true);

 const keyword = match.params.keyword;

 const pageNumber = match.params.pageNumber || 1;

 const [outlet, setOutlet] = useState(
  (userInfo.isAdmin && userInfo.outlet) ||
   (userInfo.isCompanyLeader && userInfo.outlet)
   ? userInfo.outlet
   : "ALL OUTLETS"
 );

 const dispatch = useDispatch();

 const employeesList = useSelector((state) => state.employeesList);
 const { loading, error, employees, page, pages } = employeesList;

 const sheetDetails = useSelector((state) => state.sheetDetails);
 const { sheet } = sheetDetails;
 const spreadsheetNameToImport = "sheetImportCuti";

 // eslint-disable-next-line
 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }

  if (!outlet && userInfo.outlet) {
   setOutlet(userInfo.outlet);
  }
  showActiveEmployee === activeEmployee
   ? setIsActiveEmployee(true)
   : setIsActiveEmployee(false);

  dispatch(getSheetDetails(spreadsheetNameToImport));
  dispatch(listEmployees(keyword, pageNumber));
 }, [
  keyword,
  pageNumber,
  userLoading,
  userInfo,
  showActiveEmployee,
  spreadsheetNameToImport,
  outlet,
  error,
  history,
 ]);

 const showActiveEmployeeOptions = options([
  activeEmployee,
  "Resigned employee",
 ]);

 const showOutletOptions = options([
  "ALL OUTLETS",
  "SRIWIJAYA",
  "SUDIRMAN",
  "SETIABUDI",
  "MAHAKAM",
  "TAMAN TEMAN",
  "MANAGEMENT",
  "KHALID MEDAN",
  "KHALID BINTARO",
 ]);

 return (
  <>
   <>
    <Container>
     <Row style={{ justifyContent: "center" }}>
      <Col md="auto">
       <h3>Employees</h3>
      </Col>
     </Row>

     <Row className="justify-content-md-center">
      <Col md="auto">
       <ToggleButtonGroup type="radio" name="options" defaultValue={"Grid"}>
        <ToggleButton
         key="Grid"
         id="tbg-radio-1"
         value="Grid"
         name="radio"
         onChange={(e) => setIsGrid(e.target.value === "Grid")}
         style={{
          backgroundColor: isGrid ? "#dc3545" : "#6c757d",
          border: "none", // Remove the outline
         }}
        >
         Grid
        </ToggleButton>
        <ToggleButton
         key="Table"
         id="tbg-radio-2"
         value="Table"
         name="radio"
         onChange={(e) => setIsGrid(e.target.value === "Grid")}
         style={{
          backgroundColor: !isGrid ? "#dc3545" : "#6c757d",
          border: "none", // Remove the outline
         }}
        >
         Table
        </ToggleButton>
       </ToggleButtonGroup>
      </Col>
     </Row>
    </Container>
    <Row>
     <Col>
      <h5>{outlet}</h5>
     </Col>
    </Row>
    <Row className="justify-content-between">
     <Col md="auto">
      <Form>
       <Form.Group controlId="showActiveEmployee">
        <Form.Control
         as="select"
         value={showActiveEmployee}
         onChange={(e) => setShowActiveEmployee(e.target.value)}
         className="btn btn-danger" // Applying danger variant styling similar to Button
        >
         {showActiveEmployeeOptions}
        </Form.Control>
       </Form.Group>
      </Form>
     </Col>
     {userInfo.isAdmin || userInfo.isCompanyLeader ? (
      <Col md="auto">
       <Form>
        <Form.Group controlId="outlet">
         <Form.Control
          as="select"
          value={outlet}
          onChange={(e) => setOutlet(e.target.value)}
          className="btn btn-danger"
         >
          {showOutletOptions}
         </Form.Control>
        </Form.Group>
       </Form>
      </Col>
     ) : (
      <></>
     )}

     <Col md="auto">
      <Route
       render={({ history }) => (
        <SearchBox
         history={history}
         pageName={"employeeList"}
         keyword={keyword}
        />
       )}
      />{" "}
     </Col>
    </Row>

    {sheet ? (
     <Row style={{ fontSize: "80%" }}>
      <Col md="auto">Last updated employee:</Col>
      <Col md="auto">{sheet.employeeLastUpdatedName}</Col>
      <Col md="auto">
       {"at "}
       {sheet.employeeLastUpdatedAt
        ? dayjs(sheet.employeeLastUpdatedAt).format("D-MMM-YYYY HH:mm:ss wib")
        : null}
      </Col>
      <Col md="auto">
       <i
        class="fas fa-user"
        style={{ color: "green", paddingRight: "2px" }}
       ></i>
       <strong style={{ paddingRight: "2px" }}>{`Active:${countActiveEmployee(
        employees,
        true,
        outlet
       )} | `}</strong>
       <i
        class="fas fa-sign-out-alt"
        style={{ color: "red", paddingRight: "2px" }}
       ></i>
       <strong>{`Resigned:${countActiveEmployee(
        employees,
        false,
        outlet
       )}`}</strong>
      </Col>
      <Col md="auto">
       <img
        alt="Verified"
        src={verifiedIcon}
        style={{ width: "18px", height: "18px" }}
       />
       <strong style={{ paddingRight: "2px" }}>{`Tetap:${countStatusEmployee(
        employees,
        true,
        outlet
       )} | `}</strong>
       <i
        class="fas fa-user-clock"
        style={{ color: "gray", paddingRight: "2px" }}
       ></i>
       <strong>{`Probation:${countStatusEmployee(
        employees,
        false,
        outlet
       )}`}</strong>
      </Col>
     </Row>
    ) : (
     <></>
    )}
   </>
   {loading ? (
    <Loading />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : isGrid ? (
    <>
     <Row>
      {userInfo.isAdmin ||
      userInfo.isOutletLeader ||
      userInfo.isCompanyLeader ? (
       employees.map((employee) =>
        isActiveEmployee ? (
         (!employee.dateResign && outlet === employee.outlet) ||
         (!employee.dateResign && outlet === "ALL OUTLETS") ? (
          <Col key={employee._id} sm={12} md={6} lg={4} xl={3}>
           <Employee stuentDetails={employee} />
          </Col>
         ) : null
        ) : (employee.dateResign && outlet === employee.outlet) ||
          (employee.dateResign && outlet === "ALL OUTLETS") ? (
         <Col key={employee._id} sm={12} md={6} lg={4} xl={3}>
          <Employee stuentDetails={employee} />
         </Col>
        ) : null
       )
      ) : (
       <Col key={employees._id} sm={12} md={6} lg={4} xl={3}>
        <Employee stuentDetails={employees} />
       </Col>
      )}
     </Row>
     <Paginate pages={pages} page={page} keyword={keyword ? keyword : ""} />
    </>
   ) : (
    <>
     <EmployeesTableView isActiveEmployee={isActiveEmployee} outlet={outlet} />
    </>
   )}
  </>
 );
};

export default EmployeeListView;
