import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import {
 getEmployeeDetails,
 deleteEmployee,
 deleteEmployeeSchedule,
} from "../actions/employeeActions";
import { EMPLOYEE_UPDATE_RESET } from "../constants/employeeConstant";
import dayjs from "dayjs";
import GoBackButton from "../components/goBackButton";
import { getAge, getWorkDuration, stringIncludes } from "../utils/utils";
import { updateEmployee } from "../actions/employeeActions";
import { EMPLOYEE_DETAILS_REQUEST } from "../constants/employeeConstant";
import { WhatsAppLink } from "../components/whatsapp";
import { EmailLink } from "../components/email";
import UploadProfilePicture from "../components/uploadProfilePicture";
import { logout } from "../actions/userActions";
import IconStarRating from "../components/iconStarRating";

const EmployeeDetailsView = ({ match, history }) => {
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;

 const [employeeID, setEmployeeID] = useState("");
 const [name, setName] = useState("");
 const [outlet, setOutlet] = useState("");
 const [ktpID, setKtpID] = useState("");
 const [addressOfKTP, setAddressOfKTP] = useState("");
 const [address, setAddress] = useState("");

 const [status, setStatus] = useState("");
 const [position, setPosition] = useState("");
 const [sex, setSex] = useState("");
 const [bpjsKesehatan, setBpjsKesehatan] = useState("");
 const [bpjsKesehatanDescription, setBpjsKesehatanDescription] = useState("");
 const [bpjsKetenagakerjaan, setBpjsKetenagakerjaan] = useState("");
 const [bpjsKetenagakerjaanDescription, setBpjsKetenagakerjaanDescription] =
  useState("");

 const [placeOfBirth, setPlaceOfBirth] = useState("");
 const [dateOfBirth, setDateOfBirth] = useState(null);
 const [dateIn, setDateIn] = useState(null);
 const [dateResign, setDateResign] = useState(null);
 const [phone, setPhone] = useState("");
 const [email, setEmail] = useState("");
 const [clockIn, setClockIn] = useState(null);
 const [clockIn2, setClockIn2] = useState(null);
 const [clockIn3, setClockIn3] = useState(null);
 const [reasonResign, setReasonResign] = useState(null);
 const [datePermanent, setDatePermanent] = useState(null);
 const [bank, setBank] = useState("");
 const [bankAccount, setBankAccount] = useState(null);
 const [motherName, setMotherName] = useState("");
 const [positionChange, setPositionChange] = useState("");
 const [kkID, setKkID] = useState("");
 const [npwpID, setNpwpID] = useState("");
 const [ukuranBaju, setUkuranBaju] = useState("");
 const [education, setEducation] = useState("");
 const [educationMajor, setEducationMajor] = useState("");
 const [university, setUniversity] = useState("");
 const [dateGraduation, setDateGraduation] = useState(null);
 const [religion, setReligion] = useState("");
 const [emergencyName, setEmergencyName] = useState("");
 const [emergencyRelation, setEmergencyRelation] = useState("");
 const [emergencyPhone, setEmergencyPhone] = useState("");
 const [grade, setGrade] = useState(0);
 const [maritalStatus, setMaritalStatus] = useState("");
 const [lateInTolerance, setLateInTolerance] = useState("");

 const [isDisabled, setIsDisabled] = useState(true);

 const dispatch = useDispatch();
 const employeeDetails = useSelector((state) => state.employeeDetails);
 const { loading, error, employee } = employeeDetails;
 const employeeUpdate = useSelector((state) => state.employeeUpdate);
 const { success: successUpdate } = employeeUpdate;

 const refresh = () => {
  setEmployeeID(employee.employeeID);
  setName(employee.name);
  setOutlet(employee.outlet);
  setKtpID(employee.ktpID);
  setAddressOfKTP(employee.addressOfKTP);
  setAddress(employee.address);
  setStatus(employee.status);
  setPosition(employee.position);
  setSex(employee.sex);
  setBpjsKesehatan(employee.bpjsKesehatan);
  setBpjsKesehatanDescription(employee.bpjsKesehatanDescription);
  setBpjsKetenagakerjaan(employee.bpjsKetenagakerjaan);
  setBpjsKetenagakerjaanDescription(employee.bpjsKetenagakerjaanDescription);
  setPlaceOfBirth(employee.placeOfBirth);
  setDateOfBirth(employee.dateOfBirth);
  setDateIn(employee.dateIn);
  setDateResign(employee.dateResign);
  setPhone(employee.phone);
  setEmail(employee.email);
  setClockIn(employee.clockIn || null);
  setClockIn2(employee.clockIn2 || null);
  setClockIn3(employee.clockIn3 || null);
  setReasonResign(employee.reasonResign);
  setDatePermanent(employee.datePermanent || null);
  setBank(employee.bank);
  setBankAccount(employee.bankAccount);
  setMotherName(employee.motherName);
  setPositionChange(employee.positionChange);
  setKkID(employee.kkID);
  setNpwpID(employee.npwpID);
  setUkuranBaju(employee.ukuranBaju);
  setEducation(employee.education);
  setEducationMajor(employee.educationMajor);
  setUniversity(employee.university);
  setDateGraduation(employee.dateGraduation || null);
  setReligion(employee.religion);
  setEmergencyName(employee.emergencyName);
  setEmergencyRelation(employee.emergencyRelation);
  setEmergencyPhone(employee.emergencyPhone);
  setGrade(employee.grade);
  setMaritalStatus(employee.maritalStatus);
  setLateInTolerance(employee.lateInTolerance);
 };

 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }
  if (!employee || match.params.id !== employee._id) {
   dispatch({ type: EMPLOYEE_DETAILS_REQUEST });
   dispatch(getEmployeeDetails(match.params.id));
  }
  if (employee) {
   refresh();
  }
  if (successUpdate) {
   dispatch({ type: EMPLOYEE_UPDATE_RESET });
   dispatch(getEmployeeDetails(match.params.id));
  } // eslint-disable-next-line
 }, [
  dispatch,
  history,
  successUpdate,
  userInfo,
  loading,
  employee,
  match,
  userLoading,
  error,
 ]);

 //const navigateToEdit = () => {
 // history.push({
 //  pathname: `/employee/edit/${employee._id}`,
 //  state: { employeeProps: employee },
 // });
 //};

 const deleteEmployeeHandler = () => {
  if (window.confirm("Are you sure deleting this employee")) {
   dispatch(deleteEmployee(employee._id));
   history.push("/employeeList");
  }
 };

 const deleteEmployeeScheduleHandler = () => {
  if (window.confirm("Are you sure reseting schedule?")) {
   dispatch(deleteEmployeeSchedule(employee._id));

   setClockIn(null);
   setClockIn2(null);
   setClockIn3(null);
  }
 };

 const submitHandler = () => {
  if (!employee) {
   return;
  }

  dispatch(
   updateEmployee({
    _id: employee._id,
    employeeID,
    name,
    outlet,
    ktpID,
    addressOfKTP,
    address,
    status,
    position,
    sex,
    bpjsKesehatan,
    bpjsKesehatanDescription,
    bpjsKetenagakerjaan,
    bpjsKetenagakerjaanDescription,
    placeOfBirth,
    dateOfBirth,
    dateIn,
    dateResign,
    phone,
    email,
    clockIn: clockIn ? dayjs(clockIn).toDate() : "",
    clockIn2: clockIn2 ? dayjs(clockIn2).toDate() : "",
    clockIn3: clockIn3 ? dayjs(clockIn3).toDate() : "",
    reasonResign,
    datePermanent,
    bank,
    bankAccount,
    motherName,
    positionChange,
    kkID,
    npwpID,
    ukuranBaju,
    education,
    educationMajor,
    university,
    dateGraduation: dateGraduation ? dayjs(dateGraduation).toDate() : "",
    religion,
    emergencyName,
    emergencyRelation,
    emergencyPhone,
    grade,
    maritalStatus,
    lateInTolerance,
   })
  );
  dispatch(getEmployeeDetails(match.params.id));
 };

 const defaultOptions = "Select...";

 // Fungsi untuk mengubah tanggal menjadi format yang diterima input type="date"
 const dateForPicker = (date) => {
  if (date) {
   return dayjs(date).format("YYYY-MM-DD");
  }
 };

 // Fungsi untuk mengubah string tanggal menjadi objek Date
 const dateFromDateString = (dateString) => {
  return dayjs(dateString).toDate();
 };

 const getAgeLong = () => {
  return getAge(dateOfBirth);
 };

 const handleClockInChange = (e) => {
  const timeValue = e.target.value; // waktu dari input, format HH:mm
  const currentDate = dayjs().format("YYYY-MM-DD"); // tanggal saat ini

  // Gabungkan tanggal saat ini dengan waktu input
  const clockInDayjs = dayjs(`${currentDate} ${timeValue}`, "YYYY-MM-DD HH:mm");

  setClockIn(clockInDayjs); // Simpan objek dayjs ke dalam state
 };

 const handleClockInChange2 = (e) => {
  const timeValue = e.target.value; // waktu dari input, format HH:mm
  const currentDate = dayjs().format("YYYY-MM-DD"); // tanggal saat ini

  // Gabungkan tanggal saat ini dengan waktu input
  const clockInDayjs = dayjs(`${currentDate} ${timeValue}`, "YYYY-MM-DD HH:mm");

  setClockIn2(clockInDayjs); // Simpan objek dayjs ke dalam state
 };

 const handleClockInChange3 = (e) => {
  const timeValue = e.target.value; // waktu dari input, format HH:mm
  const currentDate = dayjs().format("YYYY-MM-DD"); // tanggal saat ini

  // Gabungkan tanggal saat ini dengan waktu input
  const clockInDayjs = dayjs(`${currentDate} ${timeValue}`, "YYYY-MM-DD HH:mm");

  setClockIn3(clockInDayjs); // Simpan objek dayjs ke dalam state
 };

 return (
  <>
   <Row>
    <Col md={4}>
     <GoBackButton onClick={() => history.goBack()}></GoBackButton>
    </Col>
    <Col md={4}>
     <h3>{isDisabled ? "Employee Details" : "Edit Employee"}</h3>
    </Col>
    <Col md="auto">
     {userInfo.isAdmin ? (
      isDisabled ? (
       <Button
        type="button"
        variant="danger"
        onClick={() => setIsDisabled(false)}
        style={{ gap: "20px" }}
       >
        Edit
       </Button>
      ) : (
       <>
        <Button
         type="button"
         variant="secondary"
         onClick={() => {
          setIsDisabled(true);
          refresh();
         }}
         className="me-1"
        >
         Cancel
        </Button>
        <Button
         type="submit"
         variant="danger"
         onClick={() => {
          submitHandler();
          setIsDisabled(true);
         }}
         disabled={isDisabled}
         className="me-3"
        >
         Apply
        </Button>
        {!isDisabled && userInfo.isAdmin && (
         <Button
          variant="danger"
          style={{ justifyContent: "right" }}
          onClick={deleteEmployeeHandler}
         >
          <i className="fas fa-trash"></i>
          {" Delete Employee"}
         </Button>
        )}
       </>
      )
     ) : null}
    </Col>
   </Row>

   <UploadProfilePicture />

   <Row className="mt-3">
    <Col md={3}>
     <h4 className="underline">Work</h4>
    </Col>
    <Col md="auto">{phone && <WhatsAppLink phone={phone} />}</Col>
    <Col md="auto">{email && <EmailLink email={email} />}</Col>
   </Row>

   <Row>
    <Col md={4}>
     <Form.Group controlId="employeeID">
      <Form.Label>Employee ID</Form.Label>
      <Form.Control
       type="number"
       placeholder="Your employee ID"
       value={employeeID}
       onChange={(e) => setEmployeeID(e.target.value)}
       disabled={isDisabled}
       onWheel={(e) => e.target.blur()}
      ></Form.Control>
     </Form.Group>
     <Form.Group controlId="status">
      <Form.Label>
       Status<span>*</span>
      </Form.Label>
      <Form.Select
       value={status}
       onChange={(e) => setStatus(e.target.value)}
       disabled={isDisabled}
      >
       <option value="">{defaultOptions}</option>
       <option value="TETAP">Tetap</option>
       <option value="PROBATION">Probation</option>
       <option value="PART TIME">Part Time</option>
      </Form.Select>
     </Form.Group>
     <Form.Group controlId="outlet">
      <Form.Label>
       Outlet<span>*</span>
      </Form.Label>
      <Form.Select
       value={outlet}
       onChange={(e) => setOutlet(e.target.value)}
       disabled={isDisabled}
      >
       <option value="">{defaultOptions}</option>
       <option value="SRIWIJAYA">Sriwijaya</option>
       <option value="SUDIRMAN">Sudirman</option>
       <option value="SETIABUDI">Setiabudi</option>
       <option value="MAHAKAM">Mahakam</option>
       <option value="TAMAN TEMAN">Taman Teman</option>
       <option value="MANAGEMENT">Management</option>
       <option value="KHALID MEDAN">Khalid Medan</option>
       <option value="KHALID BINTARO">Khalid Bintaro</option>
      </Form.Select>
     </Form.Group>

     <Form.Group controlId="dateIn">
      <Row>
       <Col>
        <Form.Label>Date Join</Form.Label>
       </Col>
       <Col md={9}>
        <Form.Text className="text-muted">
         {`Duration: ${getWorkDuration(dateIn, dateResign)}`}
        </Form.Text>
       </Col>
      </Row>
      <Form.Control
       type="date"
       value={dateForPicker(dateIn)}
       onChange={(e) => setDateIn(dateFromDateString(e.target.value))}
       disabled={isDisabled}
      />
     </Form.Group>

     <Form.Group controlId="datePermanent">
      <Row>
       <Col md="auto">
        <Form.Label>Date Permanent Employee / PKWTT</Form.Label>
       </Col>
       <Col md={9}>
        <Form.Text className="text-muted">
         {datePermanent
          ? `Permanent Duration: ${getWorkDuration(datePermanent, dateResign)}`
          : ""}
        </Form.Text>
       </Col>
      </Row>
      <Form.Control
       type="date"
       value={dateForPicker(datePermanent)}
       onChange={(e) => setDatePermanent(dateFromDateString(e.target.value))}
       disabled={isDisabled || status !== "TETAP"}
      />
     </Form.Group>
    </Col>
    <Col md={4}>
     <Form.Group controlId="name">
      <Form.Label>Full Name</Form.Label>
      <Form.Control
       placeholder="Your full name"
       value={name}
       onChange={(e) => setName(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>

     <Form.Group controlId="bpjsKesehatan">
      <Form.Label>BPJS Kesehatan</Form.Label>
      <Form.Control
       type="number"
       placeholder="Enter BPJS Kesehatan"
       value={bpjsKesehatan}
       onChange={(e) => setBpjsKesehatan(e.target.value)}
       disabled={isDisabled}
       onWheel={(e) => e.target.blur()}
      ></Form.Control>
     </Form.Group>

     <Form.Group controlId="bpjsKesehatanDescription">
      <Form.Label>BPJS Kesehatan Description</Form.Label>
      <Form.Control
       placeholder="Enter BPJS Kesehatan Description"
       value={bpjsKesehatanDescription}
       onChange={(e) => setBpjsKesehatanDescription(e.target.value)}
       disabled={isDisabled}
       as="textarea"
       rows={2}
      ></Form.Control>
     </Form.Group>
     <Form.Group controlId="bpjsKetenagakerjaan">
      <Form.Label>BPJS Ketenagakerjaan</Form.Label>
      <Form.Control
       type="number"
       placeholder="Enter BPJS Ketenagakerjaan"
       value={bpjsKetenagakerjaan}
       onChange={(e) => setBpjsKetenagakerjaan(e.target.value)}
       disabled={isDisabled}
       onWheel={(e) => e.target.blur()}
      ></Form.Control>
     </Form.Group>

     <Form.Group controlId="bpjsKetenagakerjaanDescription">
      <Form.Label>BPJS Ketenagakerjaan Description</Form.Label>
      <Form.Control
       placeholder="Enter BPJS Ketenagakerjaan Description"
       value={bpjsKetenagakerjaanDescription}
       onChange={(e) => setBpjsKetenagakerjaanDescription(e.target.value)}
       disabled={isDisabled}
       as="textarea"
       rows={2}
      ></Form.Control>
     </Form.Group>
    </Col>
    <Col md={4}>
     <Form.Group controlId="position">
      <Form.Label>Position</Form.Label>
      <Form.Select
       value={position}
       onChange={(e) => setPosition(e.target.value)}
       disabled={isDisabled}
      >
       <option value="">{defaultOptions}</option>
       <option value="SERVICE">Service</option>
       <option value="KITCHEN">Kitchen</option>
       <option value="BAR/KASIR">Bar/Kasir</option>
       <option value="CAPTAIN KITCHEN">Captain Kitchen</option>
       <option value="ASST. CAPTAIN KITCHEN">Asst. Captain Kitchen</option>
       <option value="SPV">Supervisor (SPV)</option>
       <option value="HDO">Head Outlet (HDO)</option>
       <option value="COOK">Cook</option>
       <option value="ASST. COOK">Asst. Cook</option>
       <option value="DRIVER">Driver</option>
       <option value="HELPER">Helper</option>
       <option value="HELPER SENIOR">Helper Senior</option>
       <option value="CLEANING SERVICE">Cleaning Service</option>
       <option value="MAINTENANCE">Maintenance</option>
       <option value="SECURITY">Security</option>
       <option value="HEAD GUDANG">Head Gudang</option>
       <option value="ADMIN GUDANG">Admin Gudang</option>
       <option value="HEAD KITCHEN">Head Kitchen</option>
       <option value="FINANCE & PURCHASING MANAGER">
        Finance & Purchasing Manager
       </option>
       <option value="ADMIN PURCHASING">Admin Purchasing</option>
       <option value="ADMIN FINANCE">Admin Finance</option>
       <option value="CREATIVE">Creative</option>
       <option value="DESIGNER">Designer</option>
       <option value="HR SPECIALIST">HR Specialist</option>
       <option value="HR STAFF">HR Staff</option>
       <option value="HR MANAGER">HR Manager</option>
       <option value="PRODUCTION MANAGER">Production Manager</option>
       <option value="OUTLET MANAGER">Outlet Manager</option>
       <option value="COO">Chief Operating Officer</option>
       <option value="CFO">Chief Finance Officer</option>
       <option value="CEO">Chief Executive Officer</option>
      </Form.Select>
     </Form.Group>

     <Form.Group controlId="grade">
      <Form.Label>
       <Row>
        <Col md="auto">Grade / Golongan</Col>{" "}
        <Col md="auto">
         <IconStarRating employee={employee} />
        </Col>
       </Row>
      </Form.Label>
      <Form.Control
       type="number"
       placeholder="Your grade"
       value={grade}
       onChange={(e) =>
        setGrade(e.target.value ? Math.round(e.target.value) : 0)
       }
       disabled={isDisabled}
       onWheel={(e) => e.target.blur()}
      ></Form.Control>
     </Form.Group>

     <Form.Group controlId="positionChange">
      <Form.Label>Position Change/ Promosi/ Demosi/ Mutasi</Form.Label>
      <Form.Control
       value={positionChange}
       onChange={(e) => setPositionChange(e.target.value)}
       disabled={isDisabled}
       as="textarea"
       rows={positionChange ? 3 : 1}
      ></Form.Control>
     </Form.Group>
     <Form.Group controlId="dateResign">
      <Row>
       <Col>
        <Form.Label className="text-danger">Date Resign</Form.Label>
       </Col>
      </Row>
      <Form.Control
       type="date"
       value={dateForPicker(dateResign)}
       onChange={(e) => setDateResign(dateFromDateString(e.target.value))}
       disabled={isDisabled}
      />
     </Form.Group>

     <Form.Group controlId="reasonResign">
      <Form.Label className="text-danger">Reason Resign</Form.Label>
      <Form.Control
       placeholder="Enter reason resign"
       value={reasonResign}
       onChange={(e) => setReasonResign(e.target.value)}
       disabled={isDisabled || !dateResign}
       as="textarea"
       rows={reasonResign ? 3 : 1}
      ></Form.Control>
     </Form.Group>

     <Form.Group controlId="ukuranBaju">
      <Form.Label>Clothes Size / Ukuran Baju</Form.Label>
      <Form.Select
       value={ukuranBaju}
       onChange={(e) => setUkuranBaju(e.target.value)}
       disabled={isDisabled}
      >
       <option value="">{defaultOptions}</option>
       <option value="XS">XS: Extra Small</option>
       <option value="S">S: Small</option>
       <option value="M">M: Medium</option>
       <option value="L">L: Large</option>
       <option value="XL">XL: Extra Large</option>
       <option value="XXL">XXL: Double Extra Large</option>
       <option value="XXXL">XXXL: Triple Extra Large</option>
      </Form.Select>
     </Form.Group>
    </Col>
   </Row>

   <Row className="mt-3">
    <Col md={3}>
     <h4 className="underline">Work Schedule</h4>
    </Col>
   </Row>
   <Row>
    <Col md={2}>
     <Form.Group controlId="clockIn">
      <Form.Label>Shift In</Form.Label>
      <Form.Control
       type="time"
       value={clockIn ? dayjs(clockIn)?.format("HH:mm") : ""}
       onChange={handleClockInChange}
       disabled={isDisabled}
      />
     </Form.Group>
    </Col>
    <Col md={2}>
     <Form.Group controlId="clockIn2">
      <Form.Label>Shift In 2</Form.Label>
      <Form.Control
       type="time"
       value={clockIn2 ? dayjs(clockIn2)?.format("HH:mm") : ""}
       onChange={handleClockInChange2}
       disabled={isDisabled}
      />
     </Form.Group>
    </Col>
    <Col md={2}>
     <Form.Group controlId="clockIn">
      <Form.Label>Shift In 3</Form.Label>
      <Form.Control
       type="time"
       value={clockIn3 ? dayjs(clockIn3)?.format("HH:mm") : ""}
       onChange={handleClockInChange3}
       disabled={isDisabled}
      />
     </Form.Group>
    </Col>
    <Col md={2}>
     {!isDisabled && userInfo.isAdmin && (
      <Button
       variant="danger"
       style={{ justifyContent: "right" }}
       onClick={deleteEmployeeScheduleHandler}
      >
       <i className="fas fa-sync" />
       {" Reset Schedule"}
      </Button>
     )}
    </Col>
    <Col md={4}>
     <Form.Group controlId="lateInTolerance">
      <Form.Label>
       Late In Tolerance / Toleransi Keterlambatan (Menit)
      </Form.Label>
      <Form.Control
       type="number"
       value={lateInTolerance}
       onChange={(e) => setLateInTolerance(e.target.value)}
       disabled={isDisabled}
       onWheel={(e) => e.target.blur()}
      />
     </Form.Group>
    </Col>
   </Row>

   <Row className="mt-3">
    <Col md={4}>
     <h4 className="underline">Personal Data</h4>
    </Col>
   </Row>
   <Row>
    <Col md={4}>
     <Form.Group controlId="sex">
      <Form.Label>
       sex<span>*</span>
      </Form.Label>
      <Form.Select
       value={sex}
       onChange={(e) => setSex(e.target.value)}
       disabled={isDisabled}
      >
       <option value="">{defaultOptions}</option>
       <option value="Men">Men</option>
       <option value="Women">Women</option>
      </Form.Select>
     </Form.Group>

     <Form.Group controlId="placeOfBirth">
      <Form.Label>Place of Birth</Form.Label>
      <Form.Control
       placeholder="Enter place of birth"
       value={placeOfBirth}
       onChange={(e) => setPlaceOfBirth(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>

     <Form.Group controlId="dateOfBirth">
      <Row>
       <Col>
        <Form.Label>Date of Birth </Form.Label>
       </Col>
       <Col md={8}>
        <Form.Text className="text-muted">{getAgeLong()}</Form.Text>
       </Col>
      </Row>
      <Form.Control
       type="date"
       value={dateForPicker(dateOfBirth)}
       onChange={(e) => setDateOfBirth(dateFromDateString(e.target.value))}
       disabled={isDisabled}
      />
     </Form.Group>

     <Form.Group controlId="religion">
      <Form.Label>Religion / Agama</Form.Label>
      <Form.Select
       value={religion}
       onChange={(e) => setReligion(e.target.value)}
       disabled={isDisabled}
      >
       <option value="">{defaultOptions}</option>
       <option value="ISLAM">Islam</option>
       <option value="PROTESTAN">Kristen Protestan</option>
       <option value="KATOLIK">Kristen Katolik</option>
       <option value="HINDU">Hindu</option>
       <option value="BUDDHA">Buddha</option>
       <option value="KHONGHUCU">Khonghucu</option>
       <option value="LAINNYA">Lainnya</option>
      </Form.Select>
     </Form.Group>
     <Form.Group controlId="maritalStatus">
      <Form.Label>Marital Status / Status Perkawinan</Form.Label>
      <Form.Select
       value={maritalStatus}
       onChange={(e) => setMaritalStatus(e.target.value)}
       disabled={isDisabled}
      >
       <option value="">{defaultOptions}</option>
       <option value="TK">Tidak Kawin</option>
       <option value="K/0">Kawin, belum punya anak</option>
       <option value="K/1">Kawin, anak 1</option>
       <option value="K/2">Kawin, anak 2</option>
       <option value="K/3">Kawin, anak 3</option>
      </Form.Select>
     </Form.Group>
    </Col>
    <Col md={4}>
     <Form.Group controlId="ktpID">
      <Form.Label>KTP</Form.Label>
      <Form.Control
       type="number"
       placeholder="Enter KTP ID"
       value={ktpID}
       onChange={(e) => setKtpID(e.target.value)}
       disabled={isDisabled}
       onWheel={(e) => e.target.blur()} // prevent number change on scroll
      ></Form.Control>
     </Form.Group>
     <Form.Group controlId="addressOfKTP">
      <Form.Label>Address of KTP</Form.Label>
      <Form.Control
       placeholder="Enter address of KTP"
       value={addressOfKTP}
       onChange={(e) => setAddressOfKTP(e.target.value)}
       disabled={isDisabled}
       as="textarea"
       rows={3}
      ></Form.Control>
     </Form.Group>
     <Form.Group controlId="address">
      <Form.Label>Address of Domicile</Form.Label>
      <Form.Control
       placeholder="Enter address of domicile"
       value={address}
       onChange={(e) => setAddress(e.target.value)}
       disabled={isDisabled}
       as="textarea"
       rows={3}
      ></Form.Control>
     </Form.Group>
    </Col>
    <Col md={4}>
     <Form.Group controlId="bank">
      <Form.Label>Bank Name</Form.Label>
      <Form.Select
       value={bank}
       onChange={(e) => setBank(e.target.value)}
       disabled={isDisabled}
      >
       <option value="">{defaultOptions}</option>
       <option value="BCA">Bank Central Asia (BCA)</option>
       <option value="Mandiri">Bank Mandiri</option>
       <option value="BRI">Bank Rakyat Indonesia (BRI)</option>
       <option value="BNI">Bank Negara Indonesia (BNI)</option>
       <option value="CIMB">CIMB Niaga</option>
       <option value="BTN">Bank Tabungan Negara (BTN)</option>
       <option value="Danamon">Bank Danamon</option>
       <option value="Permata">Bank Permata</option>
       <option value="OCBC">OCBC NISP</option>
       <option value="Panin">Panin Bank</option>
       <option value="Maybank">Maybank Indonesia</option>
       <option value="Mega">Bank Mega</option>
       <option value="Sinarmas">Bank Sinarmas</option>
       <option value="Bukopin">Bank Bukopin</option>
       <option value="Seabank">Sea Bank</option>
       <option value="Bank Jago">Bank Jago</option>
       {/* Bank Syariah */}
       <option value="BCA Syariah">BCA Syariah</option>
       <option value="BSI">Bank Syariah Indonesia (BSI)</option>
       <option value="Muamalat">Bank Muamalat</option>
       <option value="BRI Syariah">BRI Syariah</option>
       <option value="BNI Syariah">BNI Syariah</option>
       <option value="Mandiri Syariah">Mandiri Syariah</option>
       <option value="Panin Syariah">Panin Dubai Syariah</option>
      </Form.Select>
     </Form.Group>
     <Form.Group controlId="bankAccount">
      <Form.Label>Bank Account</Form.Label>
      <Form.Control
       type="number"
       value={bankAccount}
       onChange={(e) => setBankAccount(e.target.value)}
       disabled={isDisabled}
       onWheel={(e) => e.target.blur()}
      ></Form.Control>
     </Form.Group>

     <Form.Group controlId="motherName">
      <Form.Label>Mother's Full Name/ Nama Lengkap Ibu Kandung</Form.Label>
      <Form.Control
       value={motherName}
       onChange={(e) => setMotherName(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>

     <Form.Group controlId="kkID">
      <Form.Label>Kartu Keluarga</Form.Label>
      <Form.Control
       type="number"
       value={kkID}
       onChange={(e) => setKkID(e.target.value)}
       disabled={isDisabled}
       onWheel={(e) => e.target.blur()}
      ></Form.Control>
     </Form.Group>

     <Form.Group controlId="npwpID">
      <Form.Label>NPWP</Form.Label>
      <Form.Control
       type="number"
       placeholder="Enter NPWP"
       value={npwpID}
       onChange={(e) => setNpwpID(e.target.value)}
       disabled={isDisabled}
       onWheel={(e) => e.target.blur()} // prevent number change on scroll
      ></Form.Control>
     </Form.Group>
    </Col>
   </Row>
   <Row className="mt-3">
    <Col md={3}>
     <h4 className="underline">Contact</h4>
    </Col>
    <Col md="auto">{phone && <WhatsAppLink phone={phone} />}</Col>
    <Col md="auto">{email && <EmailLink email={email} />}</Col>
   </Row>
   <Row>
    <Col md={4}>
     <Form.Group controlId="phone">
      <Row>
       <Col>
        <Form.Label>Phone</Form.Label>
       </Col>
      </Row>
      <Form.Control
       placeholder="Enter phone"
       value={phone}
       onChange={(e) => setPhone(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
    </Col>
    <Col md={4}>
     <Form.Group controlId="email">
      <Form.Label>Email</Form.Label>
      <Form.Control
       placeholder="Enter email"
       value={email}
       onChange={(e) => setEmail(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
    </Col>
    <Col md={4}></Col>
   </Row>
   <Row className="mt-3">
    <Col md={3}>
     <h4 className="underline">Emergency Contact</h4>
    </Col>
    <Col md="auto">
     {emergencyPhone && <WhatsAppLink phone={emergencyPhone} />}
    </Col>
   </Row>
   <Row>
    <Col md={4}>
     <Form.Group controlId="emergencyPhone">
      <Row>
       <Col>
        <Form.Label>Emergency Phone</Form.Label>
       </Col>
      </Row>
      <Form.Control
       placeholder="Enter emergency phone"
       value={emergencyPhone}
       onChange={(e) => setEmergencyPhone(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
    </Col>
    <Col md={4}>
     <Form.Group controlId="emergencyRelation">
      <Form.Label>Emergency Relation</Form.Label>
      <Form.Select
       value={emergencyRelation}
       onChange={(e) => setEmergencyRelation(e.target.value)}
       disabled={isDisabled}
      >
       <option value="">{defaultOptions}</option>
       <option value="IBU">Ibu</option>
       <option value="AYAH">Ayah</option>
       <option value="KAKAK">Kakak</option>
       <option value="ADIK">Adik</option>
       <option value="ISTRI">Istri</option>
       <option value="SUAMI">Suami</option>
      </Form.Select>
     </Form.Group>
    </Col>
    <Col md={4}>
     <Form.Group controlId="emergencyName">
      <Form.Label>Emergency Full Name</Form.Label>
      <Form.Control
       placeholder="Enter emergency name"
       value={emergencyName}
       onChange={(e) => setEmergencyName(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
    </Col>
   </Row>
   <Row className="mt-3">
    <Col md={3}>
     <h4 className="underline">Education</h4>
    </Col>
   </Row>
   <Row>
    <Col md={4}>
     <Form.Group controlId="education">
      <Form.Label>Education</Form.Label>
      <Form.Select
       value={education}
       onChange={(e) => setEducation(e.target.value)}
       disabled={isDisabled}
      >
       <option value="">{defaultOptions}</option>
       <option value="SD">SD</option>
       <option value="SMP">SMP</option>
       <option value="SMA">SMA</option>
       <option value="SMK">SMK</option>
       <option value="D1">D1</option>
       <option value="D2">D2</option>
       <option value="D3">D3</option>
       <option value="D4">D4</option>
       <option value="S1">S1</option>
       <option value="S2">S2</option>
       <option value="S3">S3</option>
      </Form.Select>
     </Form.Group>
     <Form.Group controlId="university">
      <Form.Label>University / School Name</Form.Label>
      <Form.Control
       placeholder="Enter university or school name"
       value={university}
       onChange={(e) => setUniversity(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
    </Col>
    <Col md={4}>
     <Form.Group controlId="educationMajor">
      <Form.Label>Major / Jurusan</Form.Label>
      <Form.Control
       placeholder="Enter major or jurusan"
       value={educationMajor}
       onChange={(e) => setEducationMajor(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
     <Form.Group controlId="dateGraduation">
      <Row>
       <Col md="auto">
        <Form.Label>Date Graduation</Form.Label>
       </Col>
       <Col md="auto">
        <Form.Text className="text-muted">
         {dateGraduation &&
          `${getWorkDuration(dateGraduation, dateResign)} after graduation`}
        </Form.Text>
       </Col>
      </Row>
      <Form.Control
       type="date"
       value={dateForPicker(dateGraduation)}
       onChange={(e) => setDateGraduation(dateFromDateString(e.target.value))}
       disabled={isDisabled}
      />
     </Form.Group>
    </Col>
   </Row>
  </>
 );
};

export default EmployeeDetailsView;
